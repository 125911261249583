@import "tailwindcss/base";
@import "./components/@colors.css";
@import "./components/@mixins.css";

/* /////////////////////////////////////////// */
/* 	COMPONENTS */
@import "tailwindcss/components";
@import "./components/Html.css";
@import "./components/Body.css";
@import "./components/Button.css";
@import "./components/Input-text.css";
@import "./components/Input-check.css";
@import "./components/Label.css";
@import "./components/Card.css";
@import "./components/Tabs.css";
@import "./components/Accordion.css";
@import "./components/Side-menu.css";
@import "./components/Breadcrumb.css";
@import "./components/Container.css";
@import "./components/Sucesso-screen.css";
@import "./components/Avatar.css";
@import "./components/Dropdown.css";
@import "./components/Input-toggle.css";
@import "./components/Texto.css";
@import "./components/Textarea.css";
@import "./components/TextHelper.css";
@import "./components/Show-password.css";
@import "./components/Grid.css";
@import "./components/Select.css";
@import "./components/ErrorList.css";
@import "./components/Badge.css";
@import "./components/TableBasic.css";
@import "./components/GridRelatorio.css";
@import "./components/Bars.css";
@import "./components/Toastify.css";
@import "./components/Table-separeted.css";
@import "./components/Simple-table.css";
@import "./components/nprogress.css";
@import "./components/Flatpicker.css";

/* /////////////////////////////////////////// */
/* 	UTILITIES */
@import "tailwindcss/utilities";
@import "./utilities/util.css";

